section {
    z-index: 10;
    position: relative;
    @include size(100%,100vh !important);
    padding: 4rem;
    text-align: center;

    &.center {
        .content {
            @include absolute(50%,auto,auto,50%,10);
            transform: translate(-50%,-50%);
        }
    }

    &.hero {

        .content {

            &.bottom {

                @include absolute(auto,auto,4rem,50%,10);
                @include size(100%,auto);
                transform: translate(-50%,0);
            }

            img {
                @include media-breakpoint-down(sm) {
                    max-width: 20vw;
                }
            }

        }

    }
   
    
}

.slider-main {
    @include fixed(0,auto,auto,0,10);
    @include size(100%,100vh !important);
    margin-bottom: 0;
    
    .slick-slide {
        
        position: relative;
        padding: 4rem;
        text-align: center;
        @include size(100%,100vh);
        outline: none;
        @include media-breakpoint-down(sm) {
            // padding: vw-size(32);
        }

        &.center {
            .content {
                @include absolute(50%,auto,auto,50%,10);
                transform: translate(-50%,-50%);
            }
        }

        &.start {

            .content {

                &.bottom {

                    @include absolute(auto,auto,4rem,50%,10);
                    @include size(100%,auto);
                    transform: translate(-50%,0);
                }

            }

        }

        &.thank-you {

            .content {

                &.center {

                    @include absolute(50%,auto,auto,50%,10);
                    @include size(100%,auto);
                    transform: translate(-50%,-50%);
                }

            }

        }

        .choice {
            margin: 0 auto;
            // padding: rem-calc(40) 0;
            padding: vw-size(40) 0;
            width: 75%;
            img {
                // margin-bottom: 2rem;
                margin-bottom: vw-size(32);
            }
            p {
                margin-bottom: 0;
            }
            &:not(:last-of-type) {
                border-bottom: 3px solid #fff
            }
            @include media-breakpoint-down(sm) {                
                img {
                    max-width: 5vw;
                }
            }
        }

        &.no-results {
            .content {
                @include absolute(50%,auto,auto,50%,10);
                @include size(100%,auto);
                text-align: center;
                transform: translate(-50%,-50%);
            }
        }
    
    }

    .items {
        @include size(100%,82vh);
        .item {
            
            @include size(100%,100%);
            margin-bottom: 4rem;

            display: none;
            &.show {
                display: flex;
            }


            .img {
                display: inline-flex;
                align-items: center;
                @include size(180px,auto);
                // padding: 0 2rem;
            }
            .info {
                display: inline-block;
                @include size(100%,auto);
                margin-left: 2rem;
                padding: 2rem 3rem;
                text-align: left;
                .description {
                    p {
                        font-size: rem-calc(20);
                        font-weight: 300;
                    }
                }
                hr {
                    background-color: #fff;
                    margin: 1rem 0;
                    box-shadow: none;
                }
                .food-pairing-items {
                    display: flex;
                    justify-content: space-between;
                    .food-pairing-item {
                        display: inline-flex;
                        justify-content: space-between;
                        align-items: center;
                        img {
                            display: inline-block;
                            margin-right: 1rem;
                        }
                        p {
                            margin-bottom: 0;
                        }
                    }
                }
                .attributes {
                    display: flex;
                    justify-content: space-between;
                    .attribute {
                        display: inline-flex;
                        justify-content: space-between;
                        align-items: center;
                        padding-right: 0.75rem;
                        img {
                            display: inline-block;
                            margin-right: 1rem;
                        }
                        p {
                            margin-bottom: 0;
                            display: block;
                        }
                        .label {
                            // display: inline-flex;
                            // align-items: center;
                        }
                    }
                }
            }
        }

    }

}

.status-bar {    
    @include fixed(auto,auto,4rem,4rem,15);
    @include size(calc(100% - 8rem),auto);
    color: #fff;
    display: flex;
    align-items: flex-end;
    justify-content: space-between;

    transition: all 200ms ease-in-out;
    opacity: 0;
    transform: translate(0,100%);

    .summary {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        width: 45%;

        opacity: 0;
        transition: all 350ms;        

        .your {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-bottom: 5px;

            opacity: 0;
            transition: all 350ms;        

            p {
                margin-bottom: 0;
            }
            .change-selection {
                display: block;
                font-weight: 300;
                border: 1px solid #fff;
                padding: 2px;
                border-radius: 3px;
                margin-left: 1rem;
                @include media-breakpoint-down(sm) {
                    font-size: 0.6rem;
                }
            }
            &.show {
                opacity: 1;
            }
        }

        &.show {
            opacity: 1;
        }

        @include media-breakpoint-down(sm) {
            p {
                margin-bottom: 0;
            }
            br {
                display: none;
            }
            .p25 {
                margin-bottom: 5px;
            }
        }
    }

    &.show {
        opacity: 1;
        transform: translate(0,0);
    }
}