html {
    margin-top: 0 !important;
}

body {
    background-color: #fff;
    position: relative;
    font-family: $globalFont;
    overflow-x: hidden;
    
    @include size(100%,100%);

    user-select: none;
    -webkit-user-drag: none;

    background-image: url('../img/bg.png'); 
    background-size: cover;

    @include media-breakpoint-up(lg) {

        .layer {
            @include fixed(0,0,0,0,1);
            @include size(100%,100%);
            background: linear-gradient(0deg, #E73D6D, #000);
            opacity: .65;
            
        }

        .layer-bg {
            @include fixed(0,auto,auto,0,10);
            transform: translate(-25%,0) skewX(-16deg);
            @include size(55vw,100vh);
            background-color: #fff;
            box-shadow: 0 0 15px rgba(0,0,0,0.3);
        }

        main {
            position: relative;
            z-index: 20;
            display: flex;
            justify-content: space-between;
        }
        .content-1 {
            padding: 4rem;
            // float: left;

            img,a {
                display: block;

            }
        }
        .content-2 {
            padding: 4rem;
            // float: right;
            width: 40vw;

            img,a {
                display: block;
                
            }

            .list-item {
                color: #fff;
                position: relative;
                padding: 1rem 3rem;
                margin-top: 2rem;
                margin-bottom: 2rem;
                &:before {
                    content: '';
                    @include absolute(50%,auto,auto,0,-1);
                    @include size(200%,130%);
                    transform: translate(0,-50%) skewX(-25deg);
                }
                &:nth-of-type(1) {     
                    &:before {                    
                        background: linear-gradient(90deg, #2D2652, #E73D6D);
                    }         
                }
                &:nth-of-type(2) {                
                    &:before {                    
                        background: linear-gradient(90deg, #2D2652, #E73D6D);
                    }         
                    transform: translateX(-30px);
                }
                &:nth-of-type(3) {                
                    &:before {                    
                        background: linear-gradient(90deg, #2D2652, #E73D6D);
                    }         
                    transform: translateX(-60px);
                }
                &:nth-of-type(4) {                
                    &:before {                    
                        background: linear-gradient(90deg, #2D2652, #E73D6D);
                    }         
                    transform: translateX(-90px);
                }
                &:nth-of-type(5) {                
                    &:before {                    
                        border: 1px solid #fff;
                    }         
                    transform: translateX(-120px);
                }
            }
        }

    }

    .slick-track {
        display: flex;
        align-items: center;
    }

    p.title {
        font-weight: bold;
        font-style: italic;
    }

    .content-1 {
        p {
            font-size: rem-calc(20);
        }
    }

    @include media-breakpoint-down(md) {

        .layer {
            @include fixed(0,0,0,0,-1);
            @include size(100%,100%);
            background: linear-gradient(0deg, #E73D6D, #000);
            opacity: .65;
            
        }

        main {
            display: flex;
            flex-direction: column-reverse;
            z-index: 20;
            overflow-x: hidden;
        }

        .content-1 {
            padding: 1.5rem;
            background-color: #fff;
            box-shadow: 0 0 10px rgba(0,0,0,0.3);
            
        }
        .content-2 {
            padding: 4rem 1.5rem;

            .list-item {
                color: #fff;
                position: relative;
                padding: 1rem 3rem;
                margin-top: 2rem;
                margin-bottom: 2rem;
                &:before {
                    content: '';
                    @include absolute(50%,auto,auto,0,-1);
                    @include size(200%,130%);
                    transform: translate(0,-50%) skewX(-25deg);
                }
                &:nth-of-type(1) {     
                    &:before {                    
                        background: linear-gradient(90deg, #2D2652, #E73D6D);
                    }         
                }
                &:nth-of-type(2) {                
                    &:before {                    
                        background: linear-gradient(90deg, #2D2652, #E73D6D);
                    }                             
                }
                &:nth-of-type(3) {                
                    &:before {                    
                        background: linear-gradient(90deg, #2D2652, #E73D6D);
                    }                             
                }
                &:nth-of-type(4) {                
                    &:before {                    
                        background: linear-gradient(90deg, #2D2652, #E73D6D);
                    }                             
                }
                &:nth-of-type(5) {                
                    &:before {                    
                        border: 1px solid #fff;
                    }                             
                }
            }
        }

        img {
            max-width: 100%;
        }

        .logo-mobile {
            filter: brightness(100);
        }
    }

}