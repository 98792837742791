//$debug: true;
$debug: false;

// $baseFont: 'Raleway', sans-serif;

$globalFont: 'Open Sans', sans-serif;

$maxWidthInner: 1320px;
$maxWidthOuter: 1920px;

$headerSize: 80px;
$asideSize: 240px;


// colors

$color1: #EF7F1A;

$color1: #CE1075;
$colorPrimary: #001E46;
$colorSecondary: #00b0ff;
$colorTertiary: #001b2c;
$colorCTA: #f9f871;
$globalPadding: 30px;

$colorBg1: #f3ebe8;
$colorBg2: #e8eaec;