@mixin absolute($t,$r,$b,$l,$z) {
  position: absolute;
  top: $t;
  right: $r;
  bottom: $b;
  left: $l;
  z-index: $z;
}
@mixin fixed($t,$r,$b,$l,$z) {
  position: fixed;
  top: $t;
  right: $r;
  bottom: $b;
  left: $l;
  z-index: $z;
}
@mixin relative() {
  position: relative;
  top: auto;
  right: auto;
  bottom: auto;
  left: auto;
  z-index: auto;
}
@mixin size($w,$h) {
  width: $w;
  height: $h;   
}
@mixin max($w,$h) {
  max-width: $w;
  max-height: $h;   
}
@mixin min($w,$h) {
  min-width: $w;
  min-height: $h;   
}

