body.noScroll {
	overflow:  hidden;
}

.off {
    pointer-events: none;    
}

.overflow-auto {
  overflow: auto;
}

@function rem-calc($size) {
  $remSize: $size / 16;
  @return #{$remSize}rem;
}

@function vw-size($px) {
  // @return #{($px / 1080) * 100vw};
  @return #{($px / 1080) * 100vmin};
}